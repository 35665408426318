<template>
  <div class="rectangle-login">
    <img src="../assets/smartphone.png" alt="" srcset="">
    <h1>Register Admin</h1>
    <div class="hr1"><hr></div>
    <span>S’inscrir Maintenant</span>
    <div class="hr2"><hr></div>
    <form @submit.prevent="registerAdmin" class="inputs">
      <h4>Nom</h4>
      <input type="text" v-model="nom_admin" name="text" id="text" class="inpt1" placeholder="Entrez Votre Nom">
      <h4>Prénom</h4>
      <input type="text" v-model="prenom_admin" name="text" id="text" class="inpt1" placeholder="Entrez Votre Prénom">
      <h4>Téléphone</h4>
      <input type="text" v-model="tel_admin" name="telephone" id="telephone" class="inpt1" placeholder="Entrez Votre Téléphone">
      <h4>Email</h4>
      <input type="email" v-model="email_admin" name="email" id="email" class="inpt1" placeholder="Entrez Votre Email">
      <h4>Mot De Passe</h4>
      <input type="password" v-model="password_admin" name="password" id="password" class="inpt1" placeholder="********"> 
      <div class="forgot-password">
        <a href="#" class="a1">Mot de passe oublié ?</a>
        <a href="#" class="a2">Réinitialiser</a>
      </div>
      <button class="cnx">Connecter</button> 
    </form>
    <a href="#" class="signup-link">Vous n’avez pas de compte ? S’inscrir maintenant</a>
  </div>
  
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      nom_admin: '',
      prenom_admin: '',
      email_admin: '',
      password_admin: '',
      tel_admin: ''
    };
  },
  methods: {
    async registerAdmin() {
      try {
        const response = await axios.post('https://missions-freelance-industrie.fr/admin/register', {
          nom_admin: this.nom_admin,
          prenom_admin: this.prenom_admin,
          email_admin: this.email_admin,
          password_admin: this.password_admin,
          tel_admin: this.tel_admin
        });
        if (response.data && !response.data.error) {
          this.$router.push('/loginconsultant');
        } else {
          console.error('Registration failed:', response.data.message);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }
};
</script>


<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-size: 14px;
  background-color: #F6F1F1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rectangle-login {
      min-height: 500px; 
      width: 400px;
      height: 100%;
      background-color: white;
      border-radius: 15px;
      border: 1px solid #DFDFDF;
      background: #FFF;
      box-shadow: 2px 4px 4px 0px rgba(132, 132, 132, 0.25);
      position: relative;
        margin: 0 auto;
}
.rectangle-login img {
      margin-right: 500px;
      margin-left: 20px;
      margin-top: 20px;
      width: 57px;
      height: 77px;
}
.rectangle-login h1{
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
  margin-top: 10px;
}
.rectangle-login p{
  color: #595959;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 200px;
  margin-top: 8px;
}

.hr1 {
  border-color: #D9D9D9;
  width: 120px;
  height: 1px;
  position: absolute;
  top: 185px;
  left: 10px;
  transform: translateY(-50%);
}

.hr2 {
  border-color: #D9D9D9;
  width: 120px;
  height: 1px;
  position: absolute;
  top: 185px;
  right: 10px;
  transform: translateY(-50%);
}

span {
  color: #595959;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-align: center;
  margin-top: 30px; 
}
.inputs {
  position: relative;
}
.inputs h4{
  float: left;
  color: #595959;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
  margin-left: 20px;
}
.inputs .inpt1{
  float: left;
  width: 352px;
  height: 30px;
  border-radius: 0px;
  border: none; /* Remove any existing border */
  border-bottom: 1px solid black;
  background: #FFF;
  margin-top: 10px;
  margin-left: 20px;
  text-indent: 10px;
}
.inpt1::placeholder {
  color: #B8B8B8;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-indent: 10px;
}
.forgot-password .a1 {
  position: absolute;
  bottom: -330px; 
  right: 110px;
  width: 100%;
  text-align: center;
}
.forgot-password .a2 {
  left: 150px;
  width: 100%;
  text-align: center;
}

.forgot-password .a1 {
  position: absolute;
  bottom: -380px; 
  color: #999999; 
  text-decoration: none; 
}

.forgot-password .a1:hover {
  text-decoration: underline; 
}
.forgot-password .a2 {
  position: absolute;
  bottom: -380px; 
  color: rgba(20, 108, 148, 0.95); 
  text-decoration: none; 
}

.forgot-password .a2:hover {
  text-decoration: underline; 
}
.cnx {
  background-color: rgba(20, 108, 148, 0.95);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  margin-top: 390px;
  margin-left: 50px;
  width: 280px;
  cursor: pointer;
}

.cnx:hover {
  background-color: darkblue; 
}
.signup-link {
  display: block;
  text-align: center;
  margin-top: 450px;
  color: #999999;
  text-decoration: none;
  font-size: 10px;
}

.signup-link:hover {
  text-decoration: underline;
}


</style>
