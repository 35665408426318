<template>
    <div class="container">
        <div class="rectangle-login">
            <h2>Request Password Reset</h2>
            <form @submit.prevent="requestPasswordReset" class="inputs">
                <input type="email" v-model="email" placeholder="Entrez Votre Email" class="inpt1" required />
                <button type="submit" class="cnx">Request Reset Link</button>
                <p>{{ message }}</p>
            </form>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    data() {
        return {
            email: '',
            message: ''
        };
    },
    methods: {
        async requestPasswordReset() {
            this.message = '';
            try {
                const response = await axios.post(`https://missions-freelance-industrie.fr/consultant/password/reset/${this.email}`);
                console.log(response.data);

                if (response.status === 200) {
                    this.message = response.data.message;
                } else {
                    this.message = response.data.message || 'Password reset request failed.';
                }
            } catch (error) {
                console.error(error);
                this.message = 'An error occurred while requesting the password reset link.';
            }
        },
    },
};
</script>
<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.rectangle-login {
    width: 400px;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 1px 1px 9px 0px rgba(132, 132, 132, 0.25);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rectangle-login img {}

.rectangle-login .dashboard {
    text-align: right;
}

.rectangle-login h1 {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 270px;
    margin-top: 10px;
}

.rectangle-login p {
    color: #595959;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
}




span {
    color: #595959;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    text-align: center;
    margin: 15px 0 20px 0;
}

.inputs {}

.inputs label {
    color: #595959;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
}

.inputs .inpt1 {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    border: 1px solid rgba(20, 108, 148, 0.95);
    background: #FFF;
    margin: 15px 0;
    text-indent: 10px;
}

.inpt1::placeholder {
    color: #B8B8B8;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-indent: 10px;
}





.cnx {
    width: 100%;
    margin: 30px 0 15px 0;
    background-color: rgba(20, 108, 148, 0.95);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;

}

.cnx:hover {
    background-color: darkblue;
}



@media only screen and (max-width: 600px) {
    .rectangle-login {
        width: 350px;
    }
}
</style>
  