<template>
    <div class="container">
        <div class="title">
            <h1 class="page_title">Historiques des missions</h1>
        </div>
        <div class="in__container">
            <div v-for="card in cards" :key="card._id" class="cardavalider box">
                <div class="card_body">
                    <div class="card_content">
                        <h2 class="card_title">{{ card.nom_mission }} <i class="fa-solid fa-clock-rotate-left"  style="color: #d40808;"></i></h2>
                        <div class="card_description">
                            <p class="desc">{{ card.description_mission }}</p>
                            <p class="comp">Competences: {{ card.competences_requises }}</p>
                            <div v-if="card.id_consultant" class="consultant">
                                <h3>Consultant:</h3>
                                <p><i class="fa-solid fa-user-tag"></i>Nom : {{ card.id_consultant.nom_consultant }} </p>
                                <p><i class="fa-solid fa-at"></i>Email : {{ card.id_consultant.email_consultant }}</p>
                            </div>
                        </div>
                        <div class="card_summary">
                            <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(card.created_at) }}</a>
                            <a><i class="fa-solid fa-location-dot"></i> {{ card.ville }}</a>
                            <a><i class="fa-solid fa-hourglass-start"></i> Date debut : {{
                                formatMissionDate(card.date_debut_ms) }}</a>
                            <a><i class="fa-regular fa-calendar-days"></i> Date fin : {{ formatMissionDate(card.date_fin)
                            }}</a>
                            <a><i class="fa-solid fa-hand-holding-dollar"></i>Tarif : {{ card.tarif_mission }} €</a>
                        </div>

                    </div>
                    <!-- 
                    <div class="card_actions">
                        <div class="accept">
                            <a @click="acceptCard(card)"><i class="fa-regular fa-circle-check fa-2x"></i></a>
                        </div>
                        <div class="notaccept">
                            <a @click="notAcceptCard(card)"><i class="fa-regular fa-circle-xmark fa-2x"></i></a>
                        </div> 
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

  
<script>
import axios from 'axios';
import moment from 'moment-timezone';

export default {
    data() {
        return {
            cards: [],
        };
    },
    methods: {
        formatMissionDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        async fetchMissions() {
            try {
                const response = await axios.get('https://missions-freelance-industrie.fr/mission/terminee');
                this.cards = response.data.data;

            } catch (error) {
                console.error('Axios Error: ', error);
            }
        },
    },
    async mounted() {
        await this.fetchMissions();
    },
};
</script>

  
<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.title {
    margin: 0 auto;
    width: 90%;
    margin-top: 30px;
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.in__container {
    width: 70%;
    margin-top: 25px !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.page_title,
.card_title {
    font-weight: bold;
}

.cardavalider {
    padding: 30px 20px;
    border-radius: 10px;
    margin: 15px 0;
}

.card_title {
    margin-bottom: 30px;
}

.card_body {
    display: flex;
    align-items: center;
}

.card_description .desc,
.comp {
    line-height: 2em;
}

.card_description {
    margin-bottom: 20px;
}

.card_description .comp {
    font-weight: bolder;
}

.card_body {
    display: flex;
    align-items: center;
}
.consultant {
    margin: 5px 0;
    line-height: 2em;
}
.consultant i {
    font-size: 20px;
    color: #19a7ce;
    margin-right: 0.5em
}
.card_summary {
    display: flex;
    justify-content: flex-start;
    padding: 5px 0;
    -moz-column-gap: normal;
    column-gap: normal;
    column-gap: 0.9em;
    flex-wrap: nowrap;
    flex-direction: row;
}

.card_summary svg {
    font-size: 20px;
}

.card_content {
    width: 95%;
}

.card_actions {
    width: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;
    margin-bottom: 40px;
}

.card_actions svg {
    font-size: 30px;
}

.card_actions .accept a {
    color: #29AA65;
}

.card_actions .notaccept a {
    color: #C13636;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.box {
    background: #fff;
    box-shadow: 0px 2px 7px 0px #aaaaaa;
}

.box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
}


/******/

@media only screen and (max-width: 600px) {

    .in__container {
        margin-top: 5% !important;
        width: 100%;
    }

    .title {
        margin-top: 15px;
    }

    .row {
        margin: 0;
        flex-direction: column;
    }

    .card_body {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .card_actions {
        width: 50%;
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        justify-content: space-around;
    }

    .card_actions svg {
        font-size: 40px;
    }

    .card_body .card_summary {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 5px 0;
    }

    .card_summary a {
        margin: 5px 0;
    }
}</style>
  