<template>
  <Header />
  <div class="main-container">
    <Sidebar @sidebar-opened="handleSidebarToggle"/>
    <main class="content" @scroll="handleScroll">
      <div class="content-section">
        <h1>Ajouter une Mission</h1>
        <div class="container">
          <div class="espace">
            <form>
              <label for="nom">Nom:</label>
              <input type="text" id="nom" name="nom" placeholder="Entrez le nom" v-model="nom" required>
              <span class="error-message" v-if="nomError">{{ nomError }}</span>

              <label for="nom">Ville:</label>
              <input type="text" id="ville" name="ville" placeholder="Entrez le nom" v-model="ville" required>
              <span class="error-message" v-if="villeError">{{ villeError }}</span>

              <label for="publiee">Email Client:</label>
              <input type="email" name="email" placeholder="mail@domain.com" v-model="email_client" required>
              <span class="error-message" v-if="email_clientError">{{ email_clientError }}</span>

              <label for="date_debut">Date Debut:</label>
              <input type="date" v-model="date_debut_ms" required>
              <span class="error-message" v-if="date_debut_msError">{{ date_debut_msError }}</span>

              <label for="date_fin">Date Fin:</label>
              <input type="date" v-model="date_fin" required>
              <span class="error-message" v-if="date_finError">{{ date_finError }}</span>

              <div v-if="showDateError" class="error-message">
                La date de fin ne peut pas être antérieure à la date de début.
              </div>

              <label for="duree">Durée(jours):</label>
              <input type="number" v-model="duree" required>
              <span class="error-message" v-if="dureeError">{{ dureeError }}</span>

              <label for="competences_requises">Competences requises:</label>
              <textarea id="competences_requises" name="competences_requises" cols="50" rows="8"
                v-model="competences_requises" required></textarea>
              <span class="error-message" v-if="competences_requisesError">{{ competences_requisesError }}</span>

              <label for="description">Description:</label>
              <textarea id="description" name="description" rows="8" v-model="description" required></textarea>
              <span class="error-message" v-if="descriptionError">{{ descriptionError }}</span>

              <label for="tarif">Tarif :</label>
              <input type="number" v-model="tarif" required>
              <span class="error-message" v-if="tarifError">{{ tarifError }}</span>

              <div class="actions">
                <button class="ajouter" @click="handleSubmit($event, 'a_valider')">A Valider</button>
                <button class="annoncer" @click="handleSubmit($event, 'annoncer')">Annoncer</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>


<script>
import axios from 'axios';
import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import Swal from 'sweetalert2';


export default {
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      nom: '',
      ville: '',
      email_client: '',
      description: '',
      competences_requises: '',
      tarif: '',
      date_debut_ms: '',
      date_fin: '',
      duree: '',
      statut_mission: 'non terminer',
      id_admin: '',
      id_client: '',
      id_consultant: '',
      annoncee: '',
      a_valider: '',
      id_formulaire: 'servsma@gmail.com',
      showDateError: false,
    };
  },
  watch: {
  date_debut_ms: 'calculateDuration',
  date_fin: 'calculateDuration'
  },
  computed: {
    nomError() {
      return this.nom.trim() ? '' : 'Le nom est requis.';
    },

    villeError() {
      return this.ville.trim() ? '' : 'La ville est requise.';
    },

    email_clientError() {
      return this.email_client.trim() ? '' : 'L\'email est requis.';
    },

    date_debut_msError() {
      return this.date_debut_ms ? '' : 'La date de début est requise.';
    },

    date_finError() {
      return this.date_fin ? '' : 'La date de fin est requise.';
    },

    dureeError() {
      return this.duree ? '' : 'La durée est requise.';
    },

    competences_requisesError() {
      return this.competences_requises.trim() ? '' : 'Les compétences sont requises.';
    },

    descriptionError() {
      return this.description.trim() ? '' : 'La description est requise.';
    },

    tarifError() {
      return this.tarif ? '' : 'Le tarif est requis.';
    }
  },
  methods: {

    calculateDuration() {
        if (this.date_debut_ms && this.date_fin) {
            const startDate = new Date(this.date_debut_ms);
            const endDate = new Date(this.date_fin);

            const ONE_DAY = 1000 * 60 * 60 * 24; 
            const daysDifference = Math.round((endDate - startDate) / ONE_DAY);

           
            // if (daysDifference < 30) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Erreur',
            //         text: "Vous devez choisir au moins une durée d'un mois.",
            //         confirmButtonText: 'OK',
            //         allowOutsideClick: false
            //     });
            //     return;
            // }

            this.duree = daysDifference;
        }
    },
    async handleSubmit(event, mission_statut) {
      event.preventDefault();
      if (this.nomError || this.villeError || this.email_clientError || this.date_debut_msError ||
        this.date_finError || this.dureeError || this.competences_requisesError ||
        this.descriptionError || this.tarifError) {
        return;
      }
      if (mission_statut == 'a_valider') {
        this.annoncee = false;
        this.a_valider = true;
      } else if (mission_statut === 'annoncer') {
        this.annoncee = true;
        this.a_valider = false;
      }
      const startDate = new Date(this.date_debut_ms);
      const endDate = new Date(this.date_fin);

      // if (endDate < startDate) {
      //   alert("La date de fin ne peut pas être antérieure à la date de début.");
      //   return;
      // }

      if (endDate < startDate) {
        this.showDateError = true;
        return;
      }

      let formData = new FormData();
      formData.append('nom_mission', this.nom);
      formData.append('ville', this.ville);
      formData.append('email_client', this.email_client);
      formData.append('duree', this.duree);
      formData.append('description_mission', this.description);
      formData.append('competences_requises', this.competences_requises);
      formData.append('tarif_mission', this.tarif);
      formData.append('date_debut_ms', this.date_debut_ms);
      formData.append('date_fin', this.date_fin);
      formData.append('id_admin', this.id_admin);
      formData.append('id_client', this.id_client);
      formData.append('id_consultant', this.id_consultant);
      formData.append('annoncee', this.annoncee);
      formData.append('a_valider', this.a_valider);
      formData.append('id_formulaire', this.id_formulaire);

      axios
        .post('https://missions-freelance-industrie.fr/missions', formData)
        .then(
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Mission ajoutée avec succès!',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timerProgressBar: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/dashboard');
            }
          })
        )
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Erreur!',
            text: "Échec de l'ajout de la mission.",
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timerProgressBar: false
          });
        });
      this.showDateError = false;
    },
  },

}
</script>

<style scoped>
.main-container {
  display: flex;
}
.content {
  flex: 1;
  /* padding: 20px; */
}


.content-section {
  min-height: 86.7vh;
  margin-top: 30px;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.espace {
  width: 50%;
  border-radius: 10px;
  border: 1px solid #E9E0E0;
  background: #FFF;
  box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  text-align: center;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5em;
  padding: 40px 60px;
}

label {
  font-size: 16px;
  font-weight: bold;
}

input {
  padding: 8px;
  width: 100%;
  height: 30px;
  border-radius: 0px;
  border: none;
  /* Remove any existing border */
  border-bottom: 1px solid black;
  background: white;
  outline: none;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
}

input:focus {
  border-bottom: 2px solid #000000;
  transition: 0.1s;
}

.actions {
  width: 100%;
  display: flex;
  column-gap: 3em;
}

.ajouter {
  font-size: 20px;
  padding: 10px 10px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  background-color: #19A7CE;
  outline: none;
  width: 100%;
  text-align: center;
}

.ajouter:hover {
  background-color: #146C94;
  color: #ffffff;
  transition: 0.3s;
}

.annoncer {
  width: 100%;
  background: #21b500;
  font-size: 20px;
  padding: 10px 10px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
}

.annoncer:hover {
  background-color: #159414;
  color: #ffffff;
  transition: 0.3s;
}

select {
  width: 170px;
  height: 35px;
  margin-bottom: 50px;
  margin-right: 180px;
}

.error-message {
  color: #fff;
  background: #ff000082;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #ff0000ad;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .content {
    padding: 20px;
  }

  .espace {
    width: 100%;
  }

  .actions {
    width: 100%;
    display: flex;
    -moz-column-gap: 1em;
    column-gap: 1em;
  }

  .ajouter {
    font-size: 16px;
    padding: 15px 10px;
  }

  .annoncer {
    font-size: 16px;
    padding: 15px 10px;
  }
}</style>