<template>
    <Header />
    <div class="main-container">
        <Sidebar />
        <main class="content">
            <div class="content-section">
                <h1>Modifier la Mission</h1>
                <div class="container">
                    <div class="espace">
                        <form @submit.prevent="handleSubmit">
                            <label for="nom">Nom:</label>
                            <input type="text" v-model="mission.nom_mission" id="nom" name="nom_mission" placeholder="Entrez le nom">
                            <span class="error-message" v-if="nomError">{{ nomError }}</span>

                            <label for="prénom">Ville:</label>
                            <input type="text" v-model="mission.ville" id="ville" name="ville" placeholder="Entrez la ville">
                            <span class="error-message" v-if="villeError">{{ villeError }}</span>

                            <label for="date_debut">Date Debut:</label>
                            <input type="date" v-model="mission.date_debut_ms" name="date_debut_ms">
                            <span class="error-message" v-if="date_debut_msError">{{ date_debut_msError }}</span>

                            <label for="date_fin">Date Fin:</label>
                            <input type="date" v-model="mission.date_fin" name="date_fin">
                            <span class="error-message" v-if="date_finError">{{ date_finError }}</span>

                            <label for="mission">Durée(mois):</label>
                            <input type="text" v-model="mission.duree" id="duree" name="duree" placeholder="Entrez la durée">
                            <span class="error-message" v-if="dureeError">{{ dureeError }}</span>

                            <label for="competences_requises">Competences requises:</label>
                            <textarea v-model="mission.competences_requises" id="competences_requises" name="competences_requises"
                                cols="50" rows="8" required></textarea>
                            <span class="error-message" v-if="competences_requisesError">{{ competences_requisesError }}</span>

                            <label for="description">Description:</label>
                            <textarea v-model="mission.description_mission" id="description_mission" name="description_mission"
                                cols="50" rows="8" required></textarea>
                            <span class="error-message" v-if="descriptionError">{{ descriptionError }}</span>

                            <label for="tarif">Tarif:</label>
                            <input type="number" v-model="mission.tarif_mission" id="tarif" name="tarif" placeholder="Entrez la durée">
                            <span class="error-message" v-if="tarifError">{{ tarifError }}</span>

                            <button class="modifier">Modifier</button>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>


<script>
import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    components: {
        Header,
        Sidebar,
    },
    data() {
        return {
            mission: {
                nom_mission: '',
                ville: '',
                email_client: '',
                date_debut_ms: '',
                date_fin: '',
                duree: '',
                competences_requises: '',
                description_mission: '',
                tarif_mission: '',
                annoncee: true,
                attribuee: true,
                a_valider: false,
                a_attribuer: false,
                terminee: true,
                id_admin: null,
                id_client: null,
                id_consultant: null,
            },
        };
    },
    watch: {
      'mission.date_debut_ms': 'calculateDuration',
      'mission.date_fin': 'calculateDuration'
    },
    computed: {
        nomError() {
            return this.mission.nom_mission.trim() ? '' : 'Le nom est requis.';
        },
        villeError() {
            return this.mission.ville.trim() ? '' : 'La ville est requise.';
        },
        email_clientError() {
            return this.mission.email_client.trim() ? '' : 'L\'email est requis.';
        },
        date_debut_msError() {
            return this.mission.date_debut_ms ? '' : 'La date de début est requise.';
        },
        date_finError() {
            return this.mission.date_fin ? '' : 'La date de fin est requise.';
        },
        dureeError() {
            return this.mission.duree ? '' : 'La durée est requise.';
        },
        competences_requisesError() {
            return this.mission.competences_requises.trim() ? '' : 'Les compétences sont requises.';
        },
        descriptionError() {
            return this.mission.description_mission.trim() ? '' : 'La description est requise.';
        },
        tarifError() {
            return this.mission.tarif_mission ? '' : 'Le tarif est requis.';
        }
    },
    created() {
        axios.get(`https://missions-freelance-industrie.fr/missions/${this.$route.params.id}`)
            .then(response => {
                response.data.data.date_debut_ms = this.formatDate(response.data.data.date_debut_ms);
                response.data.data.date_fin = this.formatDate(response.data.data.date_fin);            
                Object.assign(this.mission, response.data.data);    
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },
    methods: {
      formatDate(dateStr) {
        return dateStr.split('T')[0];
      },
      calculateDuration() {
          if (this.mission.date_debut_ms && this.mission.date_fin) {
            const startDate = new Date(this.mission.date_debut_ms);
            const endDate = new Date(this.mission.date_fin);
            const diff = endDate - startDate;
            const days = diff / (24 * 60 * 60 * 1000);

            this.mission.duree = days.toString();
          }
        },
        handleSubmit() {
            if (this.nomError || this.villeError || this.email_clientError || this.date_debut_msError ||
                this.date_finError || this.dureeError || this.competences_requisesError ||
                this.descriptionError || this.tarifError) {
                return;
            }

            axios
                .put(`https://missions-freelance-industrie.fr/missions/${this.$route.params.id}`, this.mission)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès!',
                        text: 'Mission modifiée avec succès!',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timerProgressBar: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$router.push('/dashboard');
                        }
                    });
                })
                .catch(error => {
                    if (error.response && error.response.status === 500) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur!',
                            text: 'Candidat existe déjà',
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            timerProgressBar: false
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur!',
                            text: "Échec de la modification de la mission.",
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            timerProgressBar: false
                        });
                    }
                });
        },
    }
};
</script>



<style scoped>
.main-container {
  display: flex;
}

.content {
  flex: 1;
  /* padding: 20px; */
}



.content-section {
  min-height: 86.7vh;
  margin-top: 30px;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.espace {
  width: 50%;
  border-radius: 10px;
  border: 1px solid #E9E0E0;
  background: #FFF;
  box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  text-align: center;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5em;
  padding: 40px 60px;
}

label {
  font-size: 16px;
  font-weight: bold;

}

input {
  padding: 8px;
  width: 100%;
  height: 30px;
  border-radius: 0px;
  border: none;
  /* Remove any existing border */
  border-bottom: 1px solid black;
  background: white;
  outline: none;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
}

input:focus {
  border-bottom: 2px solid #000000;
  transition: 0.1s;
}

.modifier {
  font-size: 20px;
  padding: 10px 10px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  background-color: #19A7CE;
  outline: none;
  width: 100%;
  text-align: center;
}

.modifier:hover {
  background-color: #146C94;
  color: #ffffff;
  transition: 0.3s;
}

select {
  width: 170px;
  height: 35px;
  margin-bottom: 50px;
  margin-right: 180px;
}

.error-message {
  color: #fff;
  background: #ff000082;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #ff0000ad;
  font-size: 0.875rem; 
}


@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .content {
    padding: 20px;
  }
  .espace {
    width: 100%;
  }
}
</style>