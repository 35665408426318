<template>
  <div class="title">
    <h1 class="page_title">Liste des missions validées</h1>
  </div>
  <div class="container">
    <div class="form">
      <div v-for="card in missions" :key="card._id" class="cardavalider box">
        <div class="card_body">
          <div class="card_content">
            <h2 class="card_title">{{ card.nom_mission }}</h2>
            <div class="card_description" style="margin-bottom:20px;">
              <p class="desc">{{ card.description_mission }}</p>
              <p class="comp">Competences: {{ card.competences_requises }}</p>
            </div>
            <div class="card_summary">
              <a><i class="fa-regular fa-clock"></i> Publiée le : {{ formatMissionDate(card.created_at) }}</a>
              <a><i class="fa-solid fa-location-dot"></i> {{ card.ville }}</a>
              <a><i class="fa-solid fa-hourglass-start"></i> Date debut : {{ formatMissionDate(card.date_debut_ms) }}</a>
              <a><i class="fa-regular fa-calendar-days"></i> Date fin : {{ formatMissionDate(card.date_fin) }}</a>
              <a><i class="fa-solid fa-hand-holding-dollar"></i> Tarif : {{ card.tarif_mission }} €</a>
            </div>
          </div>
          <div class="card_actions">
            <!--<div class="edit">
              <router-link :to="`/typeforminput/${card._id}`">
                <button class="typeform-btn">Typeform</button>
              </router-link>
            </div>-->
            <div class="check">
              <router-link :to="`/missiondetails/${card._id}`"><i class="fa-solid fa-circle-info"></i></router-link>
            </div>
            <div class="edit">
              <router-link :to="`/modifiermission/${card._id}`"><i class="fa-solid fa-file-pen"></i></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar">
      <router-link to="/ajoutermission"><i class="fa-solid fa-folder-plus"></i> Mission</router-link>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios'
import moment from 'moment-timezone';

export default {
  data() {
    return {
      missions: [],
    }
  },
  methods: {
    formatMissionDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    getMissions() {
      axios.get('https://missions-freelance-industrie.fr/mission/annoncee')
        .then(response => {
          this.missions = response.data.data
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    },
  },
  created() {
    this.getMissions()
  },
};
</script>

  
<style scoped>
* {
  font-family: Helvetica, sans-serif;
}

.title {
  margin: 0 auto;
  width: 90%;
  margin-top: 30px;
  text-align: center;
}

.page_title,
.card_title {
  font-weight: bold;
}

.container {
  width: 90%;
  margin-top: 25px !important;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.card_content .card_title {
  font-weight: bold !important;
}

.container .form {
  width: 80%;
}

.container .sidebar {
  width: 20%;
  padding: 30px 0px;
  text-align: right;
}

.container .sidebar a {
  padding: 15px 10px;
  background: #146C94;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
  text-decoration: none;
}

.container .sidebar a:hover {
  background: #19A7CE;
  transition: 0.3s;
}

.cardavalider {
  padding: 30px 20px;
  border-radius: 10px;
  margin: 15px 0;
}

.card_title {
  margin-bottom: 30px;
}

.card_body {
  display: flex;
  align-items: center;
}

.card_description .desc,
.comp {
  line-height: 2em;
}

.card_description .desc {}

.card_description .comp {
  font-weight: bolder;
}

.card_summary {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  column-gap: normal;
}

.card_summary svg {
  font-size: 25px;
}

.card_content {
  width: 95%;
}

.card_actions {
  width: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5em;
}

.card_actions i {
  font-size: 30px;
}

.card_actions .check a {
  color: #146C94;
}

.card_actions .edit a {
  color: #146C94;
}



.box {
    background: #fff;
    box-shadow: 0px 2px 7px 0px #aaaaaa;
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}


/******/

@media only screen and (max-width: 750px) {

  .container {
    margin-top: 10% !important;
    width: 100%;
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
  }
  .title {
        margin-top: 15px;
    }

  .container .sidebar {
    width: 100%;
    padding: 30px 0px;
    text-align: left;
  }

  .card_body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card_actions {
    width: 100%;
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
}

  .card_actions svg {
    font-size: 40px;
  }

  .card_body .card_summary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 0;
  }

  .card_summary a {
    margin: 5px 0;
  }
  .container .form {
    width: 100%;
}
.cardavalider{
    padding: 30px 20px;
    border-radius: 10px;
    margin: 30px 0;
}
}

.typeform-btn {
  background-color: red;
  border: none;
  border-radius: 25px;
  width: 80px;
  height: 40px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 16px;
  margin-right: 15px;
}
</style>
  