
<template>
    <div class="container">
        <div class="card">
            <!-- <router-link :to="`/postuler/${mission._id}`" class="postuler"
                style="margin-right: 10px;">Postuler</router-link> -->
                <router-link
                v-if="!mission.terminee && !mission.attribuee"
                :to="`/postuler/${mission._id}`"
                class="postuler"
                style="margin-right: 10px;"
            >Postuler</router-link>
            <hr>
            <h1>{{ mission.nom_mission }}</h1>
            <div class="details section">
                <div>
                    <p><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(mission.created_at) }}</p>
                </div>
                <div>
                    <p><i class="fa-solid fa-map-pin"></i> {{ mission.ville }}</p>
                </div>
                <div>
                    <p><i class="fa-solid fa-hourglass-start"></i> Démarage : {{ formatMissionDate(mission.date_debut_ms) }}</p>
                </div>
                <div>
                    <p><i class="fa-solid fa-hourglass-end"></i> Duree : {{ convertToMois(mission.duree) }}</p>
                </div>
            </div>
            <div class="competences section">
                <h2>Compétences requises</h2>
                <p>{{ mission.competences_requises }}</p>
            </div>
            <div class="description section">
                <h2>Description de la mission</h2>
                <p>{{ mission.description_mission }}</p>
            </div>
        </div>
        <!--
        <div class="sidebar">
            <h2>sidebar</h2>
        </div>
    -->

    </div>
</template>

<script>
import axios from 'axios';
// import moment from 'moment';
import moment from 'moment-timezone';

export default {
    name: 'MissionDetails',
    data() {
        return {
            mission: {},
        };
    },
    created() {
        axios
            .get(`https://missions-freelance-industrie.fr/missions/${this.$route.params.id}`)
            .then(response => {
                this.mission = response.data.data;
            })
            .catch(error => {
                console.error(error);
            });
    },
    methods: {
        convertToMois(days) {
            const months = Math.floor(days / 30);
            const remainingDays = days % 30;
            
            if (months > 0) {
                return `${months} mois`;
            } else {
                return `${remainingDays} jours`;
            }
        },
        formatMissionDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        async fetchForMission() {
            try {
                const response = await axios.post('https://missions-freelance-industrie.fr/missions/getFormMission', {
                    missionId: this.$route.params.id
                });
                if (response.data.formData && response.data.formData._links && response.data.formData._links.display) {
                    window.location.href = response.data.formData._links.display;
                } else {
                    alert('Fetched form mission successfully, but missing redirection URL!');
                }

            } catch (error) {
                console.error('Failed to fetch form mission:', error);
            }
        }
    }
};
</script>


<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.container {
    width: 90%;
    margin: 5% auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    justify-content: space-around;
}

.section {
    margin: 40px 0;
}

.section h2 {
    margin-bottom: 10px;
    color: #333;
}

.section p {
    color: #444;
}


.card {
    width: 70%;
    border-radius: 15px;
    border: 1px solid #d4c6c6;
    background-color: white;
    box-shadow: 0px 2px 7px 0px #aaaaaa;
    padding: 30px 20px;
}

/* .card .postuler {
    background-color: #19A7CE;
    border-radius: 8px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    padding: 15px 25px;

}

.card .postuler:hover {
    background-color: #0d7bae;
} */

.postuler {
    display: inline-block;
    padding: 0.8em 0.8em;
    border-radius: 1em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.2em;
    letter-spacing: 0.1em;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: bold;
}

.postuler:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #19A7CE;
    border-radius: 1em;
    z-index: -2;
}

.postuler:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #0d7bae;
    transition: all 0.3s;
    border-radius: 1em;
    z-index: -1;
}

.postuler:hover {
    color: #fff;
}

.postuler:hover:before {
    width: 100%;
}

.card hr {
    margin: 30px 0;
    border-top: 2px solid #f6f1f1;
}

.card .details {
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.details i {
    font-size: 20px;
    color: #19a7ce;
}

.details p {
    padding: 0 5px;
}

.card .competences {
    margin-bottom: 40px;

}

.competences p {
    text-transform: uppercase;
    line-height: 2em;
}

.description p {
    line-height: 2em;
}

.sidebar {
    width: 30%;
    height: auto;
    border-radius: 15px;
    border: 1px solid #d4c6c6;
    background-color: white;
    box-shadow: 0px 2px 7px 0px #aaaaaa;
    padding: 30px 20px;
}

@media only screen and (max-width: 750px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .postuler {
        padding: 1em 1em;
        font-size: 1.1em;

    }

    .card {
        /* margin-top: 80px; */
        width: 100%;
    }

    .card .details {
        flex-direction: column;
        justify-content: space-between;
        row-gap: 1em;
    }

}

@media only screen and (max-width: 550px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .postuler {
        padding: 1em 1em;
        font-size: 1.1em;

    }


    .card {
        /* margin-top: 80px; */
        width: 100%;
    }

    .card .details {
        flex-direction: column;
        justify-content: space-between;
        row-gap: 1em;
    }

}
</style>