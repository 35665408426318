<template>
  <Header />
  <div class="main-container">
    <Sidebar />
    <main class="content">
      <div class="content-section">
        <h1>Listes Des Consultants</h1>

        <div class="liste tableFixHead">
          <table>
            <thead>
              <tr>
                <th>Nom/Prenom</th>
                <th>Email</th>
                <th>Mission</th>
                <th>Taux journalier moyen</th>
                <th>Détails</th>
                <th>Supprimer</th>
              </tr>
            </thead>
            <tr v-for="consultant in transformedConsultants" :key="consultant._id">
              <td>{{ consultant.nom_consultant }}</td>
              <td>{{ consultant.email_consultant }}</td>
              <td>{{ consultant.nom_mission }}</td>
              <td>{{ consultant.TJM_consultant }}</td>
              <!--<td><a :href="consultant.CV_consultant"><i class="fa-solid fa-file-pdf"></i></a></td>-->
              <td><router-link :to="`/modifierconsultant/${consultant._id}`"><i
                    class="fa-solid fa-circle-info"></i></router-link></td>
              <td><a href="#" @click.prevent="deleteConsultant(consultant,consultant._id)"><i class="fa-solid fa-user-minus"></i></a></td>
            </tr>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import Swal from 'sweetalert2';


export default {
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      consultants: [],
      missions: {} 
    };
  },
  methods: {
    // transformCVPath(originalPath) {
    //   return originalPath.replace('public/', 'https://missions-freelance-industrie.fr/');
    // },
    async deleteConsultant(consultant, id) {
        Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Voulez-vous supprimer le consultant?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const consultantToDelete = this.consultants.find(consultant => consultant._id === id);
              if (!consultantToDelete) {
                console.error('erreur.');
                return;
              }

              const missionId = consultant.id_mission._id;
              if (missionId) {
                await axios.put(`https://missions-freelance-industrie.fr/missions/${missionId}`, {
                  attribuee: false,
                  a_valider: true,
                  annoncee: false,
                  terminee: false
                });
              }
              const response = await axios.delete(`https://missions-freelance-industrie.fr/consultants/${id}`);
              if (response.status === 200) {
                this.consultants = this.consultants.filter(consultant => consultant._id !== id);
                Swal.fire({
                  icon: 'success',
                  title: 'Succès',
                  text: 'Consultant supprimé et mission transformée vers mission à valider.'
                });
              } else {

              }
            } catch (error) {
              console.error('Axios Error: ', error);
              Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Une erreur s\'est produite. Veuillez réessayer.'
              });
            }
          }
        });
      },
    async fetchMissionById(missionId) {
      const actualId = missionId._id;
        try {
          const response = await axios.get(`https://missions-freelance-industrie.fr/missions/${actualId}`);
          if(response.data) {
            return response.data.data.nom_mission;
          }
        } catch (error) {
          console.error('Error fetching mission by ID:', error);
          return null;
        }
    },
  },
  async mounted() {
    try {
      const response = await axios.get('https://missions-freelance-industrie.fr/consultants');
      this.consultants = response.data.data;

      // for (let consultant of this.consultants) {
      //       if (consultant.id_mission) {
      //           const mission = await this.fetchMissionById(consultant.id_mission);
      //           consultant.missionDetails = mission;
      //       }
      //   }
      for (let consultant of this.consultants) {
        if (consultant.id_mission) {
          consultant.nom_mission = await this.fetchMissionById(consultant.id_mission);
        }
      }
    } catch (error) {
      console.error('Axios Error: ', error);
    }
  },
  computed: {
    transformedConsultants() {
      return this.consultants.map(consultant => {
        return {
          ...consultant,
          // CV_consultant: this.transformCVPath(consultant.CV_consultant),
        };
      });
    },
  }
}
</script>


<style scoped>
.main-container {
  display: flex;
}

.content {
  flex: 1;
  /* padding: 20px; */
}



.content-section {
  margin-top: 30px;
  min-height: 85vh;
}

h1 {
  text-align: center;
}

.liste {
  width: 80%;
  height: auto;
  background-color: white;
  margin-top: 30px !important;
  margin: 0 auto;
}

table i {
  font-size: 25px;
  color: #1278a6
}

table i:hover {
  font-size: 25px;
  color: #125c7f;
  transition: 0.3s;
}


table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  background-color: white;
}


tr {
  border-bottom: 1px solid #ddd;
  text-align: center;
}

tr:hover {
  background: #f9f9f9;
  transition: 0.3s;
}

th {
  background: #146c94;
  padding: 16px 10px;
  color: #fff;
}

td {
  padding: 7px;
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
  .content-section{
    margin-top: 15px;
  }

  .content {
    padding: 20px;
  }
  .liste {
    width: 100%;
  }
}

.tableFixHead {
  overflow-y: auto;
  height: 400px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0px;
}

table {
  border-collapse: collapse;
  width: 100%;
}
</style>