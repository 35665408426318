<template>
    <div class="container">
        <h1>Détails de mission attribuée</h1>
        <div class="in__container">
            <div class="form">
                <div class="form-body">
                    <div class="row">
                        <!-- Mission Details Card -->
                        <div class="cardavalider box">
                            <h1>{{ mission.nom_mission }}</h1>
                            <div class="details section">
                                <p><i class="fa-regular fa-clock"></i><b> Publié le : </b>{{ formatMissionDate(mission.created_at) }}</p>
                                <p><i class="fa-solid fa-map-pin"></i><b> {{ mission.ville }}</b></p>
                                <p><i class="fa-solid fa-hourglass-start"></i> <b>Démarage :</b> {{ formatMissionDate(mission.date_debut_ms) }}</p>
                                <p><i class="fa-solid fa-hourglass-end"></i><b> Fin : </b>{{ formatMissionDate(mission.date_fin) }}</p>
                                <p><i class="fa-solid fa-hand-holding-dollar"></i><b> Tarif : </b>{{ mission.tarif_mission }} €</p>
                            </div>
                            <div class="competences section">
                                <h2>Compétences requises</h2>
                                <p>{{ mission.competences_requises }}</p>
                            </div>
                            <div class="description section">
                                <h2>Description de la mission</h2>
                                <p>{{ mission.description_mission }}</p>
                            </div>
                            <div class="description section">
                                <h3>Montant Total</h3>
                                <p>{{ montantTotal }} €</p>
                            </div>
                        </div>
                        
                        <button class="cloturer-button" @click="cloturerMission">Clôturer Mission</button>

                        <!-- Mission Date and Details Table -->
                        <div class="cardavalider box">
                            <table class="table">
                                <tr class="head_line">
                                    <td>TJM</td>
                                    <td colspan="4">{{ consultantData.TJM_consultant }} €</td>
                                </tr>
                                <tr class="head_line">
                                    <td>Mois</td>
                                    <td>Jour Travaillés</td>
                                    <td>Facture HT</td>
                                </tr>
                                <tr v-for="(dates, month) in groupedDates" :key="month">
                                    <td>{{ month }}</td>
                                    <td>{{ dates.length }}</td>
                                    <td>{{ consultantData.TJM_consultant * dates.length }}</td>
                                </tr> 
                                <tr class="head_line">
                                    <td>Total</td>
                                    <td colspan="4">{{ montantTotal }} €</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sidebar with consultant details and selected/refused dates -->
            <div class="sidebar-wrapper">
                <div class="sidebar box 1">
                    <h2 class="card-title">Détail du consultant</h2>
                    <div class="consultant-details">
                        <div class="consultant-card">
                            <div v-if="consultantData" class="id_card">
                                <div class="consultant-info">
                                    <i class="fa-solid fa-user-tie"></i>
                                    <p class="consultant-name">{{ consultantData.nom_consultant }}</p>
                                    <p class="consultant-email">{{ consultantData.email_consultant }}</p>
                                    <p><b>Montant Total: {{ montantTotal }} €</b></p>
                                </div>
                                <!-- <div class="id_actions">
                                    <a @click="sendInvoiceEmail" class="cv-link">Envoyer Facture</a>
                                </div> -->
                            </div>
                            <p v-else>Aucun consultant attribué</p>
                            <p v-if="errorMsg" class="error">{{ errorMsg }}</p>
                        </div>
                    </div>
                    
                    <div class="date_selected">
                        <h3 style="margin-bottom:15px;">Dates sélectionnées:</h3>
                        <div id="flatpickr-calendar" ref="flatpickr"></div>
                        <button @click="validateDates" class="valider" style="display: block; margin: 15px auto;">Valider dates</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer></footer>
</template>

  
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import VCalendar from 'v-calendar';
import '../../node_modules/v-calendar/src/styles/index.css';






export default {
    name: 'MissionAttribuee',
    props: {
        consultantData: Object, 
    },
    data() {
        return {
            config: {
                inline: true,
                mode: 'multiple',
                defaultDate: null,
                onChange: this.handleDateChange,
                highlight: [],
            },
            mission: {},
            consultantData: {},
            refusedDates: [],  
            loading: true,
            consultantId: null,
            selectedDates: [],
            errorMsg: null,
            // highlightedDates: []
        };
    },
    computed: {
        filteredJourDeTravaill() {
            if (this.consultantData && Array.isArray(this.consultantData.jour_de_travaill)) {
                return this.consultantData.jour_de_travaill.filter(date => {
                    return !this.refusedDates.includes(date);
                });
            }
            return [];
        },
    },
    methods: {
        async updateDatesInDatabase() {
            try {
                // if (!this.selectedDates.length) {
                //     return;
                // }
                const formattedDates = this.selectedDates.map(date => moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'));
                const response = await axios.get(`https://missions-freelance-industrie.fr/consultants/${this.consultantId}`);
                const currentDates = response.data.data.jour_de_travaill || [];
                const allDates = [...new Set([...currentDates, ...formattedDates])];
                const updateResponse = await axios.put(`https://missions-freelance-industrie.fr/consultants/${this.consultantId}`, {
                    jour_de_travaill: allDates
                });
                if (updateResponse.data && updateResponse.data.message) {
                    Swal.fire({
                        title: 'Succès',
                        text: 'Les dates ont été mises à jour.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        location.reload();
                    });
                } else {
                    console.error('Failed to update jour_de_travaill:', updateResponse.data);
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Échec de la mise à jour des dates.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }

            } catch (error) {
                console.error('Error updating jour_de_travaill:', error);
                Swal.fire({
                    title: 'Erreur',
                    text: 'Une erreur s\'est produite lors de la mise à jour des dates.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        },
        openFlatpickr() {
            if (this.flatpickrInstance) {
                this.flatpickrInstance.open();
            }
        },
        handleDateChange(selectedDates) {
            const previousDates = this.selectedDates.map(date => moment(date).format('YYYY-MM-DD'));
            const currentDates = selectedDates.map(date => moment(date).format('YYYY-MM-DD'));

            // Find the dates that were deselected and refuse them immediately.
            const deselectedDates = previousDates.filter(date => !currentDates.includes(date));
            deselectedDates.forEach(date => this.refuseDate(date));

            // Update the current selected dates, but don't persist to database yet.
            this.selectedDates = currentDates;
            this.flatpickrInstance.set('selectedDates', this.selectedDates);

            
        },
        async validateDates() {
            try {
                const formattedDates = this.selectedDates.map(date => moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'));
                const response = await axios.get(`https://missions-freelance-industrie.fr/consultants/${this.consultantId}`);
                const currentDates = response.data.data.jour_de_travaill || [];
                const allDates = [...new Set([...currentDates, ...formattedDates])];

                const updateResponse = await axios.put(`https://missions-freelance-industrie.fr/consultants/${this.consultantId}`, {
                    jour_de_travaill: allDates
                });

                if (updateResponse.data && updateResponse.data.message) {
                    Swal.fire({
                        title: 'Succès',
                        text: 'Les dates ont été mises à jour.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        location.reload();
                    });
                } else {
                    console.error('Failed to update jour_de_travaill:', updateResponse.data);
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Échec de la mise à jour des dates.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                console.error('Error updating jour_de_travaill:', error);
                Swal.fire({
                    title: 'Erreur',
                    text: 'Une erreur s\'est produite lors de la mise à jour des dates.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        },
        async cloturerMission(mission) {
            try {
                // const missionId = mission._id;
                const response = await axios.post(`https://missions-freelance-industrie.fr/missions/${this.$route.params.id}/cloturer`);
                if (response.data && response.data.message) {
                    Swal.fire('Succès', 'Mission clôturée avec succès', 'success');
                    this.fetchMission();
                } else {
                    Swal.fire('Erreur', 'Something went wrong.', 'error');
                }
            } catch (error) {
                console.error('Error while closing mission:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    Swal.fire('Erreur', error.response.data.message, 'error');
                } else {
                    Swal.fire('Erreur', 'Failed to close the mission.', 'error');
                }
            }
        },
        formatMissionDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        formatTableDate(date) {
            return moment(date).format('MMMM-DD');
        },
        getFileName(path) {
            let fileName = path.split('/').pop();
            fileName = fileName.split('.')[0] + '.pdf';
            return fileName;
        },
        getCvDownloadUrl(filename) {
            return `https://missions-freelance-industrie.fr/download-cv/${filename}`
        },

        fetchMission() {
            axios.get(`https://missions-freelance-industrie.fr/missions/attributed/${this.$route.params.id}`)
                .then(response => {
                    this.mission = response.data.data;
                    this.consultantData = this.mission.id_consultant;
                    this.consultantId = this.consultantData._id;
                    if (this.mission.date_debut_ms) {
                        this.config.defaultDate = this.formatMissionDate(this.mission.date_debut_ms);
                    }
                    if (this.consultantData.jour_de_travaill) {
                        this.selectedDates = [...this.consultantData.jour_de_travaill];
                        this.initFlatpickr();
                    }
                    if (this.consultantData.jour_de_travaill) {
                        this.config.highlight = [...this.consultantData.jour_de_travaill.map(date => this.formatMissionDate(date))];
                        this.initFlatpickr();
                    }

                    // if (this.consultantData.jour_de_travaill) {
                    //     this.highlightedDates = [...this.consultantData.jour_de_travaill.map(date => this.formatMissionDate(date))];
                    // }
                    this.loading = false;

                })
                .catch(error => {
                    console.error(error);
                    this.errorMsg = "Failed to fetch mission details.";
                });
        },
        async refuseDate(date) {
                try {
                    Swal.fire({
                        title: 'Confirmation',
                        text: 'Voulez vous vraiment supprimer cette date ?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Non',
                        reverseButtons: false
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const consultantId = this.consultantData._id;
                            const requestData = { date: date };
                            const response = await axios.post(`https://missions-freelance-industrie.fr/consultants/delete-refused-dates/${consultantId}`, requestData);

                            if (response.data.success) {
                                if (this.consultantData.jour_de_travaill) {
                                    const index = this.consultantData.jour_de_travaill.indexOf(date);
                                    if (index !== -1) {
                                        this.consultantData.jour_de_travaill.splice(index, 1);
                                    }
                                    this.refusedDates.push(date);
                                    
                                    Swal.fire({
                                        title: 'Succès',
                                        text: 'Date supprimée!',
                                        icon: 'success',
                                        confirmButtonText: 'OK'
                                    }).then(() => {
                                        location.reload();
                                    });
                                }
                            } else {
                                console.error('Failed to refuse date:', response.data.message);
                            }
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            location.reload();
                        }
                    });
                } catch (error) {
                    console.error('Error refusing date:', error);
                }
            },
        async sendInvoiceEmail() {
            try {
                const consultantId = this.consultantData._id;
                const refusedDates = this.refusedDates;
                const response = await axios.post(`https://missions-freelance-industrie.fr/consultants/${consultantId}/send-invoice-email`, { refusedDates });

                if (response.status === 200) {
                    Swal.fire({
                        title: 'Succès',
                        text: 'La facture a été envoyée à l\'email de consultant.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                } else {
                    console.error('Failed to send email');
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Échec de l\'envoi de l\'email.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        },
        initFlatpickr() {
            if (this.flatpickrInstance) {
                this.flatpickrInstance.destroy();
            }
            
            this.flatpickrInstance = flatpickr(this.$refs.flatpickr, {
                ...this.config,
                defaultDate: this.selectedDates.map(date => moment(date).format('YYYY-MM-DD'))
            });
        }
    },
    created() {
        this.fetchMission();
    },
    computed: {
        filteredJourDeTravaill() {
            if (this.consultantData.jour_de_travaill)
                return this.consultantData.jour_de_travaill.filter(date => !this.refusedDates.includes(date));
        },
        montantTotal() {
            if (this.consultantData && Array.isArray(this.consultantData.jour_de_travaill)) {
                return this.consultantData.jour_de_travaill.length * this.consultantData.TJM_consultant;
            }
            return 0;
        },
        montantTotalTTC() {
            if (this.consultantData && Array.isArray(this.consultantData.jour_de_travaill)) {
                const total =  (this.consultantData.jour_de_travaill.length * this.mission.tarif_mission)*0.2;
                return total + (this.consultantData.jour_de_travaill.length * this.mission.tarif_mission);
            }
            return 0;
        },
        groupedDates() {
            const grouped = {};

            if (this.consultantData.jour_de_travaill) {
                this.consultantData.jour_de_travaill.forEach(date => {
                    const monthYear = moment(date).format('MMMM-YYYY');
                    if (!grouped[monthYear]) {
                        grouped[monthYear] = [];
                    }
                    grouped[monthYear].push(date);
                });
            }

            return grouped;
        },
    },
    mounted() {
        this.initFlatpickr();
        // this.flatpickrInstance = flatpickr(this.$refs.flatpickr, {
        //     ...this.config,
        //     onChange: this.handleDateChange,
        //     defaultDate: this.selectedDates.map(date => moment(date).format('YYYY-MM-DD'))
        // });
    },
    beforeDestroy() {
        if (this.flatpickrInstance) {
            this.flatpickrInstance.destroy();
        }
    }
};
</script>
  
    
<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.container {
    margin-top: 30px !important;
    margin: 0 auto;
}

.in__container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    margin-top: 30px !important;
    justify-content: space-between;
    align-items: flex-start;
}

.container .title {
    width: 90%;
    margin: 0 auto;
}

.in__container .form {
    width: 57%;
}

.page_title,
.card_title {
    font-weight: bold;
}

.sidebar {
    flex-direction: column;
    align-items: center;
}

.sidebar h2 {
    margin-bottom: 20px;
}

.in__container .sidebar {
    width: 100%;
    margin: 15px;
    padding: 30px;
    border: 1px solid #e9e0e0;
    border-radius: 10px;
    display: flex;
}

/* .in__container .sidebar a {
     padding: 15px 10px;
      background: #146C94;
      color: #fff;
      border-radius: 5px;
      font-size: 18px;
      text-decoration: none;
} */

.in__container .sidebar a:hover {
    /* background: #19A7CE; */
    transition: 0.3s;
}

.cardavalider {
    padding: 30px 20px;
    border: 1px solid #e9e0e0;
    border-radius: 10px;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
}

.cardavalider .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.details p {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5em;

}

.details i {
    font-size: 20px;
    color: #19a7ce;
}

.consultant-details i {
    font-size: 40px;
}

.form-body h1 {
    text-align: center;
}

/* .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  } */

.cv_card {
    display: flex;
    width: 90%;
    padding: 0px 25px;
    margin: 0 auto;
    border: 1px solid #e9e0e0;
    border-radius: 10px;
    background: #e8e8e8;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.card_actions i {
    font-size: 26px;
}

.cv_card .cv {
    text-decoration: none;
    font-weight: bold;
    color: #000;
}

.link {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: auto;
}

.link a {
    margin: 10px 0;
}

.actions {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.box {
    background: #fff;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
}

.consultant-card {
    width: 100%;
    flex: 1;
    background-color: #f4f4f4;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    padding: 20px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 1.25rem;
}

.consultant-details {
    display: flex;
    width: 100%;
    justify-content: center;
    -moz-column-gap: 1.5em;
    row-gap: 1.5em;
    align-items: center;
    flex-direction: column;
}

.consultant-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5em;
}

.consultant-name {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.consultant-email {
    color: #666;
}

.cv-link {
    background-color: #29aa65;
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
    width: fit-content;
}

.cv-link:hover {
    background-color: #218e4e;
}

.error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

/* Add your media queries here */



.date_selected {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;

}

.table {
    margin: 20px 0;
    border-collapse: collapse;
    width: 100%;
}

.table .head_line {
    font-weight: bold;
}

.table tr td {
    border: 1px solid #000;
    text-align: center;
    padding: 4px 2px;
}

.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: right;
    width: 42%;
}

.cloturer-button {
    background-color: #d40808;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px auto 0 auto;
    font-weight: bold;
    transition: background-color 0.3s;
}

.cloturer-button:hover {
    background-color: #aa0303;
}



.card_content {
    width: 95%;
}

.card_actions {
    width: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;
}

.card_actions svg {
    font-size: 30px;
}

.card_actions .accept {
    color: #29aa65;
}

.card_actions .notaccept {
    color: #d11d1d;
}
.card_actions .calendar {
    color: #19a7ce;
}

.id_card {
    display: flex;
    align-items: center;
}

.id_actions {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    flex: 1;
    align-items: center;
}

.scrollable-card {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: #fcfcfc;
}

@media only screen and (max-width: 900px) {
    .cv_card {
        width: 90%;
    }

    .link {
        width: 80%;
    }

    .actions {
        width: 20%;
    }
}

@media only screen and (max-width: 700px) {
    .row {
        margin: 0;
        flex-direction: column;
    }

    .in__container {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .in__container .form {
        width: 100%;
    }

    .sidebar-wrapper {
        width: 100%;
        margin-top: 40px;
    }

    .in__container .sidebar {
        width: 100%;
        margin: 0;
    }

    .cardavalider .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 0.5em;
        column-gap: 0.5em;
    }

    .details p {
        display: flex;
        flex-direction: row;
        align-items: center;
        row-gap: 0.5em;
        column-gap: 0.5em;
    }

}
.valider {
    background-color: #3091FC; 
    border: none;
    color: #fff; 
    padding: 10px 20px;
    border-radius: 5px; 
    cursor: pointer;
    transition: 0.3s; 
}
.valider:hover {
    background-color: #1e6ad2; 
}

</style> 

<style>
.flatpickr-calendar {
    width: 350px !important;
}

.flatpickr-month {
    text-align: center !important;
    position: relative;  
}

.flatpickr-weekdays .flatpickr-weekday {
    text-align: center !important;
}

.flatpickr-days .day {
    text-align: center !important;
    line-height: normal !important; 
}

.flatpickr-innerContainer, .flatpickr-rContainer {
    margin: 0 auto !important;
}

.flatpickr-day.selected {
    background-color: #3091FC !important;
    color: #fff !important;
}
</style>