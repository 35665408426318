<template>
  <div class="rectangle-login">
    <img src="../assets/smartphone.png" alt="" srcset="">
    <h1>Register Consultant</h1>

    <span>S’inscrir Maintenant</span>

    <form @submit.prevent="registerConsultant" class="inputs">
      <label>Nom</label>
      <input type="text" v-model="nom_consultant" name="text" id="text" class="inpt1" :placeholder="nom_consultant">
      <label>Email</label>
      <input type="email" v-model="email_consultant" name="email" id="email" class="inpt1" :placeholder="email_consultant">
      <label>Taux journalier moyen</label>
      <input type="number" v-model="tjm" name="tjm" id="tjm" class="inpt1" :placeholder="tjm">
      <label>Mot De Passe</label>
      <input type="password" v-model="password_consultant" name="password" id="password" class="inpt1" placeholder="********">
      <!-- <div class="cv-container">
          <h4>CV</h4>
          <a :href="cvPath" target="_blank" class="download-link" v-if="cvPath">CV Candidat</a>
          <input type="file" @change="handleFileUpload" class="inpt1" placeholder="Entrez Votre CV">
      </div> -->
      <!--
      <div class="forgot-password">
        <a href="#" class="a1">Mot de passe oublié ?</a>
        <a href="#" class="a2">Réinitialiser</a>
      </div>
      -->
      <button @click="handleLogin" class="cnx">Registre</button>
    </form>
    <!--<p href="#" class="signup-link">Vous n’avez pas de compte ? <a href="#">S’inscrir maintenant</a></p>-->
  </div>
  
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';


export default {
  data() {
    return {
      nom_consultant: '',
      email_consultant: '',
      password_consultant: '',
      tjm: '',
      CV_consultant: null,
      cvPath: '',
      missionId: ''
    };
  },
  created() {
    this.missionId = this.$route.query.missionId;
    this.fetchConsultantDetails();
  },
  methods: {
    transformCVPath(originalPath) {
      if (!originalPath) return '';
      return originalPath.replace('public/', 'https://missions-freelance-industrie.fr/');
    },
    async fetchConsultantDetails() {
      try {
        const response = await axios.get(`https://missions-freelance-industrie.fr/candidature/${this.$route.params.id}`);
        if (response.data && !response.data.error) {
          this.nom_consultant = response.data.nom_candidat;
          this.email_consultant = response.data.email_candidat;
          this.tjm = response.data.TJM;
          this.cvPath = this.transformCVPath(response.data.cv_candidat);
        } else {
          console.error('Fetching details failed:', response.data.message);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    async registerConsultant() {
      let formData = new FormData();
      formData.append('nom_consultant', this.nom_consultant);
      formData.append('email_consultant', this.email_consultant);
      formData.append('TJM_consultant', this.tjm);
      formData.append('password_consultant', this.password_consultant);
      // formData.append('CV_consultant', this.CV_consultant);
      formData.append('id_mission', this.missionId);

        try {
          const response = await axios.post('https://missions-freelance-industrie.fr/consultant/register', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          if (response.data && !response.data.error) {
            const consultantId = response.data._id;
          
            await this.updateMissionWithConsultantId(consultantId);
            await this.deleteConsultantFromCandidature(this.$route.params.id);

            this.$router.push('/loginconsultant');
          } else {
            // console.error('Registration failed:', response.data.message);
            // if (error.response && error.response.status === 409) {
              Swal.fire({
                        title: 'Erreur',
                        text: 'Email existe deja.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
            // }
          }
        } catch (error) {
          console.error('An error occurred:', error);
          if (error.response && error.response.status === 409) {
          Swal.fire({
            title: 'Erreur',
            text: 'Email existe déjà.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
        }
      },
    async updateMissionWithConsultantId(consultantId) {
          try {
              const response = await axios.put(`https://missions-freelance-industrie.fr/missions/${this.missionId}`, {
                  id_consultant: consultantId,
                  attribuee: true,  
                  annoncee: false,
              });
              
              if (response.data && !response.data.error) {
              } else {
                  console.error('Failed to update the mission:', response.data.message);
              }
          } catch (error) {
              console.error('Error updating mission:', error);
          }
      },
      async deleteConsultantFromCandidature(consultantId) {
          try {
              const response = await axios.delete(`https://missions-freelance-industrie.fr/candidature/${consultantId}`);
              if (response.status === 200) { 
              } else {
              }
          } catch (error) {
              console.error('Error deleting consultant:', error);
          }
      },
    // handleFileUpload(event) {
    //   this.CV_consultant = event.target.files[0];
    // },
  },
};
</script>


<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  background-color: #F6F1F1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rectangle-login {
  width: 400px;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #DFDFDF;
  background: #FFF;
  box-shadow: 1px 1px 9px 0px rgba(132, 132, 132, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rectangle-login img {

}

.rectangle-login h1 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 270px;
  margin-top: 10px;
}

.rectangle-login p {
  color: #595959;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}




span {
  color: #595959;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    text-align: center;
    margin: 15px 0 20px 0;
}

.inputs {}

.inputs label {
  color: #595959;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
}

.inputs .inpt1 {
  width: 100%;
  height: 30px;
  border-radius: 8px;
  border: 1px solid rgba(20, 108, 148, 0.95);
  background: #FFF;
  margin: 15px 0;
  text-indent: 10px;
}

.inpt1::placeholder {
  color: #B8B8B8;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-indent: 10px;
}

.forgot-password {
  display: flex;
  justify-content: space-between;
}

.forgot-password .a1 {
  text-align: center;
}

.forgot-password .a2 {
  text-align: center;
}

.forgot-password .a1 {
  color: #999999;
  text-decoration: none;
}

.forgot-password .a1:hover {
  text-decoration: underline;
}

.forgot-password .a2 {
  color: rgba(20, 108, 148, 0.95);
  text-decoration: none;
}

.forgot-password .a2:hover {
  text-decoration: underline;
}

.cnx {
  width: 100%;
  margin: 30px 0 15px 0;
  background-color: rgba(20, 108, 148, 0.95);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

}

.cnx:hover {
  background-color: darkblue;
}

.signup-link {
  text-align: center;
  color: #999999;
  text-decoration: none;
  font-size: 10px;
}

.signup-link a:hover {
  text-decoration: underline !important;
  color: #000;
}



.cv-container {
  position: relative;
  margin-right: 40px;
  /* Adjust this if needed */
}

.download-link {
  position: absolute;
  top: 250px;
  /* Adjust this to move the link up or down */
  left: 320px;
  /* This ensures the link starts at the left edge of .cv-container */
  color: rgba(20, 108, 148, 0.95);
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

.download-link:hover {
  text-decoration: underline;
}



</style>