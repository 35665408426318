<template>
  <Header />
  <div class="main-container">
    <Sidebar />
    <main class="content">
      <div class="content-section">
        <h1>Modifier le Consultant</h1>
        <div class="container">
          <div class="espace">
            <form @submit.prevent="handleSubmit">
              <label for="nom">Nom:</label>
              <input type="text" id="nom" v-model="consultant.nom_consultant" placeholder="Nom" readonly>
              <label for="email">Email:</label>
              <input type="text" id="email" v-model="consultant.email_consultant" placeholder="Email">
              <label for="tjm">Taux journalier moyen:</label>
              <input type="number" id="tjm" v-model="consultant.TJM_consultant" placeholder="tjm">
              <label for="password">Password:</label>
              <input type="text" id="password" v-model="password_consultant" placeholder="********">
              <label for="cv">CV:</label>
              <div class="file-drop-area">
                <span class="fake-btn">Choisir le CV</span>
                <span class="file-msg">ou faites glisser et déposez le fichier ici</span>
                <input class="file-input" type="file" multiple @change="handleFileUpload">
                <span class="file-name-container">{{ fileName }}</span>
              </div>
              <button class="modifier">Modifier</button>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Sidebar from '../../components/Sidebar.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import bcrypt from 'bcryptjs';

export default {
  components: {
    Header,
    Sidebar,
  },
  name: 'ModifierConsultant',
  data() {
    return {
      consultant: {},
      password_consultant: '',
      selectedFile: null,
      fileName: '',
    };
  },
  created() {
    axios
      .get(`https://missions-freelance-industrie.fr/consultants/${this.$route.params.id}`)
      .then(response => {
        this.consultant = response.data.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
    },
    async handleSubmit() {
      let formData = new FormData();
      formData.append('nom_consultant', this.consultant.nom_consultant);
      formData.append('email_consultant', this.consultant.email_consultant);
      formData.append('TJM_consultant', this.consultant.TJM_consultant);
      
      if(this.password_consultant) {
        const salt = await bcrypt.genSalt(10);
        const hashedPassword = await bcrypt.hash(this.password_consultant, salt);
        formData.append('password_consultant', hashedPassword);
      }
      
      if (this.selectedFile) {
        formData.append('CV_consultant', this.selectedFile);
      }

      axios
        .put(`https://missions-freelance-industrie.fr/consultants/${this.$route.params.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          if(response.status === 200) {
            Swal.fire({
              title: 'Succès!',
              text: 'Consultant a été modifié avec succès.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              this.$router.push('/listeconsultant');
            });
          } else {
            Swal.fire({
              title: 'Erreur!',
              text: 'Une erreur s\'est produite lors de la modification du consultant.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
        })
        .catch(error => {
          Swal.fire({
            title: 'Erreur!',
            text: 'Une erreur s\'est produite lors de la modification du consultant.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        });
    },
  },
}
</script>




<style scoped>
.main-container {
  display: flex;
}

.content {
  flex: 1;
  /* padding: 20px; */
}

@media (max-width: 850px) {
  .main-container {
    flex-direction: column;
  }

  .content-section{
    margin-top: 15px;
  }

  .content {
    padding: 20px;
  }
  .espace {
    width: 100% !important;
  }
}

.content-section {
  min-height: 86.7vh;
  margin-top: 30px;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

h1 {
  text-align: center;
}

.container {
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.espace {
  width: 50%;
  border-radius: 10px;
  border: 1px solid #E9E0E0;
  background: #FFF;
  box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.espace img {
  margin-right: 10px;
  margin-top: 8px;
}

.espace h2 {
  margin-top: 20px;
}

/* Style pour le formulaire */
form {
  width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.5em;
    padding: 40px 60px;
}

label {
  font-size: 16px;
  font-weight: bold;
}

input {
  padding: 8px;
  width: 100%;
  height: 30px;
  border-radius: 0px;
  border: none;
  /* Remove any existing border */
  border-bottom: 1px solid black;
  background: white;
  outline: none;
  margin-bottom: 10px;
}

input:focus {
  border-bottom: 2px solid #000000;
  transition: 0.1s;
}

.file-drop-area {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 25px;
  border: 2px dashed #bfbfbf;
  border-radius: 3px;
  transition: 0.2s;
}

.file-drop-area.is-active {
  background-color: rgba(255, 255, 255, 0.05);
}

.modifier {
  font-size: 20px;
  padding: 10px 10px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  background-color: #19A7CE;
  outline: none;
  width: 100%;
  text-align: center;
}

.modifier:hover {
  background-color: #146C94;
  color: #ffffff;
  transition: 0.3s;
}

/* .modifier {
  width: 80%;
  border-radius: 5px;
  border: none;
  background: rgba(20, 108, 148, 0.95);
  color: white;
  padding: 10px 20px;
} */

.fake-btn {
  flex-shrink: 0;
  background-color: #333;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
}

.file-msg {
  font-size: small;
  font-weight: 400;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #595959;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  &:focus {
    outline: none;
  }
}
</style>