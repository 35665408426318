<template>
  <div class="container">
    <h1>Saisir Des Questions</h1>

    <input type="text" v-model="formTitle" placeholder="Form Titre" class="styled-input form-title" />

    <div v-for="(input, index) in inputs" :key="index" class="input-container">
      <input type="text" v-model="input.ref" placeholder="Reference" class="styled-input" />
      <input type="text" v-model="input.title" placeholder="Question" class="styled-input" />
      
      <select v-model="input.type" class="select">
        <option value="" disabled>Choisir Type</option>
        <option value="short_text">Text</option>
        <option value="phone_number">Telephone</option>
        <option value="email">Email</option>
      </select>
      
      <button @click="removeLastInput" v-if="inputs.length > 1" class="minus-button">
        <i class="fas fa-minus"></i>
      </button>
      <button @click="addInput" class="plus-button">
        <i class="fas fa-plus"></i>
      </button>
    </div>

    <button class="add-question-button" @click="createQuestion">
      Ajouter Question
    </button>

    <div v-if="typeformLink" class="link-container">
      <h2>Typeform Link:</h2>
      <a :href="typeformLink">{{ typeformLink }}</a>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      formTitle: '',  // New data property for the form title
      inputs: [
        { ref: '', type: '', title: '' }  // Initialize empty for the user to input data
      ],
      idMission: '',
      typeformLink: '' 
    };
  },
  created() {
    this.idMission = this.$route.params.id;
  },
  methods: {
    addInput() {
      this.inputs.push({ ref: '', type: '', title: '' });
    },
    removeLastInput() {
      if (this.inputs.length > 1) {
        this.inputs.pop();
      }
    },
    async createQuestion() {
      try {
        const formData = {
          idMission: this.idMission,
          formulaire: {
            title: this.formTitle,  // Use the dynamic formTitle
            fields: this.inputs
          }
        };

        const response = await axios.post('https://missions-freelance-industrie.fr/missions/createtFormMission', formData);

        if (response.data && response.data.message) {
          alert(response.data.message); 
        }

        if (response.data && response.data.typeformLink) {
          this.typeformLink = response.data.typeformLink;
        }
      } catch (error) {
        console.error(error);
        alert('Failed to create question.'); 
      }
    }
  }
};
</script>


<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
}

.input-container {
  display: flex;
  align-items: center;
  gap: 10px; 
  margin-bottom: 10px;
}

.styled-input {
  width: 300px; 
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.plus-button, .minus-button {
  padding: 5px 10px;
  border: none;
  border-radius: 50%; 
  font-size: 20px; 
  transition: 0.3s;
}

.plus-button {
  background-color: white;
  color: blue;
}

.minus-button {
  background-color: red;
  color: white;
}

.add-question-button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s;
}

.add-question-button:hover {
  background-color: darkblue;
}

h1 {
  font-size: 2.5rem; 
  margin-bottom: 20px; 
  text-align: center; 
  font-weight: bold; 
}

.form-title {
    width: 70%;             
    margin: 0 auto 20px;    
    padding: 10px 15px;     
    font-size: 16px;        
    border: 1px solid #ccc;
    border-radius: 5px;     
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);  
}

input.styled-input, select {
    padding: 8px 12px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 50%;           
    box-sizing: border-box; 
}

.select {
    position: relative; 
    padding-right: 30px; 
}

.select::after {
    content: '\f107'; 
    font-family: "FontAwesome"; 
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%); 
    pointer-events: none; 
}


</style>